import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/outServe',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/outServe/auth',
    name: 'authority',
    component: () => import('../views/authority.vue')
  },
 
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
